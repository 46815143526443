import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'

async function basicRequest(args, api, extraOptions, access, guestCode) {
	const baseQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers) => {

			// console.log('BASIC API ACCESS CAME', access)

			if (guestCode) {
				console.log('guestCode ', guestCode)
				headers.set('x-auth-code', guestCode)
			} else if (String(access).length > 25) {
				// console.log('Bearer ' + access)
				headers.set('authorization', 'Bearer ' + access)
			}

			return headers
		}
	})

	const response = await baseQuery(args, api, extraOptions)
	return response
}

export {
	basicRequest
}