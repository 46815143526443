import { memo } from 'react'

import './IconButton.scss'
import { ReactComponent as RoundPlusIcon } from '../../icons/round_plus.svg'
import { ReactComponent as RoundPlusWhiteIcon } from '../../icons/round_plus_white.svg'

function IconButton({ title, onClick, white }) {
	return (
		<button
			className="icon-button"
		>
			<span className="icon-button__title" onClick={onClick}>
				{title}
			</span>

			{white ?
				<RoundPlusWhiteIcon className="round-plus__icon" onClick={onClick} />
				:
				<RoundPlusIcon className="round-plus__icon" onClick={onClick} />
			}
		</button>
	)
}

export default memo(IconButton)