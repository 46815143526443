import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './Venues.scss'
import Header from '../../reusableComponents/Header/Header'
import ArrowLinkBlock from '../../reusableComponents/ArrowLinkBlock/ArrowLinkBlock'
import { ReactComponent as RocketsIcon } from '../../icons/rockets.svg'
import AddButton from '../../reusableComponents/AddButton/AddButton'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import Loader from '../../reusableComponents/Loader/Loader'
import Plug from '../../reusableComponents/Plug/Plug'
import { selectCommunity } from '../../redux/selectors'
import { useGetCommunityQuery } from '../../redux/communityService/communityApiSlice'

function Venues() {
	const { t } = useTranslation()
	const { communityUid, communityName } = useSelector(selectCommunity)
	const { data = {}, isLoading } = useGetCommunityQuery(
		{ community_uid: communityUid }, { skip: !communityUid, refetchOnMountOrArgChange: true }
	)

	return (
		<>
			<Header
				title={t('Venues')}
				back
				backUrl={'/community'}
				border
				communityName={communityName}
			/>

			<main className="venues">
				{
					isLoading ?
						<Loader />
						:
						<>
							{
								data?.venues?.length < 1 ?
									<Plug
										icon={<RocketsIcon className="icon" />}
										title={t('Add venues')}
										description={t('Add a venue using the plus button')}
									/>
									:
									<>
										{
											data?.venues?.map((item, index) => (
												<ArrowLinkBlock
													key={index}
													to={`/venues/form/${item.uid}`}
													borderBottom={true}
												>
													<div className="venues__name-container">
														<span className="venues__number">
															{index + 1}
														</span>

														<span className="venues__name">
															{item?.name}
														</span>
													</div>
												</ArrowLinkBlock>
											))
										}
									</>
							}
						</>
				}

				<AddButton to={'/venue/form'} />
			</main>

			<BottomMenu />
		</>
	)
}

export default Venues