import { useState } from 'react'
import { Formik, Field } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { jwtDecode } from 'jwt-decode'

import './Registration.scss'
import BackButton from '../../reusableComponents/BackButton/BackButton'
import CheckBox from '../../reusableComponents/CheckBox/CheckBox'
import { ReactComponent as TenturLogo2Icon } from '../../icons/tentur_logo2.svg'
import Input from '../../reusableComponents/Input/Input'
import Button from '../../reusableComponents/Button/Button'
import { useRegistrationMutation } from '../../redux/userService/userApiSlice'
import { isValidEmail } from '../../utils/functions'
import { useLoginMutation } from '../../redux/authService/authApiSlice'
import { setAuthorized, setRefreshToken, setRefreshTokenTimeExpiration } from '../../redux/authService/authSlice'
import { useCreateCommunityMutation } from '../../redux/communitiesService/communitiesApiSlice'
import { setTokenData } from '../../utils/functions2'

function Registration() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [registrationUser] = useRegistrationMutation()
	const navigate = useNavigate()
	const [logIn] = useLoginMutation()
	const [createCommunity] = useCreateCommunityMutation()

	const [isLoading, setIsLoading] = useState(false)

	// Валидируем Имя
	function validateUserName(valueUserName) {
		if (valueUserName?.length === 0) {
			return t('Enter first name')
		}
	}

	// Валидируем Фамилию
	function validateUserSurname(valueUserSurname) {
		if (valueUserSurname?.length === 0) {
			return t('Enter last name')
		}
	}

	// Валидируем Email
	async function validateEmail(valueEmail) {
		if (valueEmail?.length > 0) {
			if (!isValidEmail(valueEmail)) {
				return t('Invalid email')
			}
		} else {
			return t('Enter Email')
		}
	}

	// Валидируем Пароль
	function validatePassword(valuePassword) {
		if (valuePassword.length === 0) {
			return t('Enter password')
		} else if (valuePassword?.length < 8) {
			return t('Password has to be at least 8 symbols')
		}
	}

	// Валидируем Подтверждение Пароля
	function validateRepeatPassword(valueRepeatPassword) {
		if (valueRepeatPassword.length === 0) {
			return t('Repeat password')
		} else if (valueRepeatPassword?.length < 8) {
			return t('Password has to be at least 8 symbols')
		}
	}

	// Валидация checkBox
	function validateCheckbox(valueCheckBox) {
		if (!valueCheckBox) {
			return t('I agree for the processing of personal data')
		}
	}

	return (
		<div className="registration">
			<div className="registration__back-wrapper">
				<BackButton nav={-1} />
			</div>

			<div className="registration__logo-wrapper">
				<TenturLogo2Icon className="tentur-logo__icon" />
			</div>

			<div className="registration__form">
				<h1 className="registration__form-title">
					Регистрация
				</h1>

				<p className="registration__form-description">
					Создайте аккаунт и начните свой турнир
				</p>

				<Formik
					initialValues={{
						userName: '',
						userSurname: '',
						email: '',
						password: '',
						repeatPassword: '',
						checkBox: false
					}}
					onSubmit={async (values, { setFieldError }) => {
						setIsLoading(true)

						try {
							const body = {
								first_name: values.userName,
								last_name: values.userSurname,
								email: values.email,
								password: values.password
							}

							const response = await registrationUser(body).unwrap()

							if (response?.error) {
								return
							}

							const bodyLogin = {
								email: values.email,
								password: values.password
							}

							const responseLogin = await logIn(bodyLogin).unwrap()

							setTokenData(responseLogin.access, dispatch)

							const refreshTokenExpiresAtUTC = jwtDecode(responseLogin?.refresh).exp  * 1000

							dispatch(setRefreshTokenTimeExpiration(refreshTokenExpiresAtUTC))
							dispatch(setRefreshToken(responseLogin?.refresh))
							dispatch(setAuthorized(true))

							let communityName = `${values.userSurname} ${values.userName}`

							if (communityName.length > 16) {
								if (values.userSurname.length < 17) {
									communityName = values.userSurname
								} else {
									const email = values.email.split('@')[0]

									if (email.length < 17) {
										communityName = email
									} else {
										communityName = email.slice(0, 15)
									}
								}
							}

							const newCommunityBody = {
								name: communityName,
								country_code: 'ru'
							}

							const communityResponse = await createCommunity(newCommunityBody).unwrap()

							if (communityResponse?.error) {
								return
							}

							setIsLoading(false)

							navigate('/communities')
						} catch (e) {
							setIsLoading(false)

							if (e?.data?.email) {
								setFieldError('email', 'Пользователь с таким email уже существует')
							}

							console.log('Registration catch error', e)
						}
					}}
					validate={(values) => {
						const errors = {}

						// Проверка совпадения паролей
						if (values.password !== values.repeatPassword) {
							errors.repeatPassword = 'Пароли не совпадают'
						}

						return errors
					}}
				>
					{({ values, errors, touched, handleSubmit, handleChange, setFieldError }) => (
						<form
							onSubmit={handleSubmit}
							noValidate
						>
							<Field
								as={Input}
								type="text"
								validate={validateUserName}
								onChange={handleChange}
								value={values?.userName}
								placeholder="Имя *"
								label="Имя"
								required={false}
								error={errors?.userName}
								touched={touched?.userName}
								name="userName"
								onClick={() => {
									setFieldError('userName', '')
								}}
							/>

							<Field
								as={Input}
								type="text"
								validate={validateUserSurname}
								onChange={handleChange}
								value={values?.userSurname}
								placeholder="Фамилия *"
								label="Фамилия"
								required={false}
								error={errors?.userSurname}
								touched={touched?.userSurname}
								name="userSurname"
								onClick={() => {
									setFieldError('userSurname', '')
								}}
							/>

							<Field
								as={Input}
								type="text"
								validate={validateEmail}
								onChange={handleChange}
								value={values?.email}
								placeholder="Email *"
								label="Email"
								required={true}
								error={errors?.email}
								touched={touched?.email}
								name="email"
								onClick={() => {
									setFieldError('email', '')
								}}
							/>

							<Field
								as={Input}
								type="password"
								validate={validatePassword}
								onChange={handleChange}
								value={values?.password}
								placeholder="Пароль *"
								label="Пароль"
								required={true}
								error={errors?.password}
								touched={touched?.password}
								name="password"
								onClick={() => {
									setFieldError('password', '')
								}}
							/>

							<Field
								as={Input}
								type="password"
								validate={validateRepeatPassword}
								onChange={handleChange}
								value={values?.repeatPassword}
								placeholder="Подтвердите пароль"
								label="Подтвердить пароль *"
								required={true}
								error={errors?.repeatPassword}
								touched={touched?.repeatPassword}
								name="repeatPassword"
								onClick={() => {
									setFieldError('repeatPassword', '')
								}}
							/>

							<Field
								as={CheckBox}
								onChange={handleChange}
								checked={values.checkBox}
								validate={validateCheckbox}
								required={true}
								error={errors?.checkBox}
								touched={touched?.checkBox}
								name="checkBox"
								onClick={() => {
									setFieldError('checkBox', '')
								}}
							/>

							<Button
								title="Зарегистрироваться"
								type="submit"
								loading={isLoading}
							/>
						</form>
					)}
				</Formik>
			</div>
		</div>
	)
}

export default Registration