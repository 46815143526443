import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import SmoothCollapse from 'react-smooth-collapse'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'

import './TournamentMenu.scss'
import { ReactComponent as Grids } from '../../icons/grids.svg'
import { ReactComponent as Schedule } from '../../icons/schedule.svg'
import { ReactComponent as Structure } from '../../icons/structure.svg'
import { ReactComponent as Participants } from '../../icons/participants.svg'
import { ReactComponent as Exit } from '../../icons/exit-tour.svg'
import { ReactComponent as Info } from '../../icons/info-tour.svg'
import { ReactComponent as ArrowDown } from '../../icons/arrow_down.svg'
import { ReactComponent as Cup } from '../../icons/cup.svg'
import { setTournamentData } from '../../redux/singleTournamentService/singleTournamentSlice'
import { getParameters } from '../../utils/functions'
import { selectCurrentStageNumber, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { setGuestCode } from '../../redux/authService/authSlice'
import { setNoSortedParticipants, setParticipants, setUserChangedList } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'

const DRAW_TAB = 1
const SCHEDULE_TAB = 2
const STRUCTURE_TAB = 3
const PARTICIPANTS_TAB = 4
const RESULT_TAB = 5
const INFO_TAB = 6
const EXIT_TAB = 7

function TournamentMenu({ children }) {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const params = useParams()
	const { tournamentParams = null, stageNumber = null } = params
	const { guest = null, tournamentUid = null } = getParameters(tournamentParams)
	const wildcardValue = params['*'] || ''
	const { stages_count, players, doubles } = useSelector(selectTournament)
	const currentStageNumber = useSelector(selectCurrentStageNumber)
	const { stage: options = {} } = useSelector(selectTournamentOptions)

	const [showMainMenu, setShowMainMenu] = useState(true)

	useEffect(() => {
		const parts = location.pathname.split('/')

		if (!parts?.includes('participants')) {
			dispatch(setParticipants({ participants: players || doubles || [] }))
			dispatch(setNoSortedParticipants(players || doubles || []))
			dispatch(setUserChangedList(false))
		}
	}, [players, doubles, dispatch, location.pathname])

	const currentTab = useMemo(() => {
		const parts = location.pathname.split('/')

		if (parts?.includes('draws')) {
			return DRAW_TAB
		} else if (parts?.includes('schedule')) {
			return SCHEDULE_TAB
		} else if (parts?.includes('structure')) {
			return STRUCTURE_TAB
		} else if (parts?.includes('participants')) {
			return PARTICIPANTS_TAB
		} else if (parts?.includes('result')) {
			return RESULT_TAB
		} else if (parts?.includes('info') || parts?.includes('form')) {
			return INFO_TAB
		}
	}, [location])

	const shownStage = useMemo(() => {
		if (currentTab < 4) {
			return true
		} else {
			return false
		}
	}, [currentTab])

	const handleMenuTab = useCallback((number, path) => {
		if (guest) {
			console.log('handleMenuTab guest: ', guest)
		}

		let comletedPath = guest ? `t/${tournamentUid}-${guest}/${path}` : `t/${tournamentUid}/${path}`
		const parts = location.pathname.split('/')

		if (number === EXIT_TAB) {
			if (guest) {
				dispatch(setGuestCode(null))
			}

			dispatch(setTournamentData({}))
			navigate('/tournaments')

		} else if (parts?.includes('form')) {
			if (number < 4) {
				comletedPath = `t/${wildcardValue}/${path}/` + currentStageNumber
			} else {
				comletedPath = `t/${wildcardValue}/${path}`
			}

			navigate(`/${comletedPath}`)
		} else if (number < PARTICIPANTS_TAB) {
			navigate(`/${comletedPath}/${stageNumber ? stageNumber : currentStageNumber}`)
		} else {
			navigate(`/${comletedPath}`)
		}
	}, [
		dispatch, navigate, tournamentUid, guest, stageNumber,
		currentStageNumber, location, wildcardValue
	])

	function getIconColor(menuTab) {
		const activeIconColor = 'var(--palette-live-blue)'
		const inactiveIconColor = 'var(--palette-live-grey-7)'

		return {
			fill: currentTab === menuTab ? activeIconColor : inactiveIconColor
		}
	}

	return (
		<footer className={`tournament-menu${currentTab > STRUCTURE_TAB ? '' : ' stages'}`}>
			{
				shownStage &&
				<div className="tournament-menu__arrow">
					<ArrowDown
						className={showMainMenu ?
							'arrow--down'
							:
							'arrow--up'
						}
						onClick={() => setShowMainMenu(prev => !prev)}
					/>
				</div>
			}

			{
				shownStage &&
				<div className="tournament-menu__wrapper">
					{children}
				</div>
			}

			<SmoothCollapse expanded={showMainMenu}>
				<div className={currentTab > STRUCTURE_TAB || (currentTab < STRUCTURE_TAB && stages_count < 1) ?
					'tournament-menu__footer'
					:
					'tournament-menu__footer--open'
				}>
					<Grids
						style={getIconColor(DRAW_TAB)}
						onClick={() => handleMenuTab(DRAW_TAB, 'draws')}
						className="tournament-menu__link"
					/>

					<Schedule
						onClick={() => handleMenuTab(SCHEDULE_TAB, 'schedule')}
						style={getIconColor(SCHEDULE_TAB)}
						className="tournament-menu__link"
					/>

					{
						!guest && options?.modes?.tabsAreVisible &&
						<Structure
							onClick={() => handleMenuTab(3, 'structure')}
							style={getIconColor(3)}
							className="tournament-menu__link"
						/>
					}

					<Participants
						onClick={() => handleMenuTab(PARTICIPANTS_TAB, 'participants')}
						style={getIconColor(PARTICIPANTS_TAB)}
						className="tournament-menu__link"
					/>

					<Cup
						onClick={() => handleMenuTab(RESULT_TAB, 'result')}
						style={getIconColor(RESULT_TAB)}
						className="tournament-menu__link"
					/>

					<Info
						style={getIconColor(INFO_TAB)}
						onClick={() => handleMenuTab(INFO_TAB, 'info')}
						className="tournament-menu__link"
					/>

					<Exit
						onClick={() => handleMenuTab(EXIT_TAB)}
						className="tournament-menu__link"
					/>
				</div>
			</SmoothCollapse>
		</footer>
	)
}

export default memo(TournamentMenu)