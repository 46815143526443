// Форматируем 2 игрока
function formattingFor2Players(rounds) {
	const reorganizedRounds = []

	for (const round of rounds) {
		const reorganizedRound = {
			roundName: round.roundName,
			matches: []
		}

		// Первый раунд
		if (round.roundName === 0.5 || round.roundName === 1) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}
		}
		// Итог
		else if (round.roundName === 'Result') {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}
		}

		reorganizedRounds.push(reorganizedRound)
	}

	return reorganizedRounds
}

// Форматируем 4 игрока
function formattingFor4Players(rounds) {
	const reorganizedRounds = []

	for (const round of rounds) {
		const reorganizedRound = {
			roundName: round.roundName,
			matches: []
		}

		// Первый раунд
		if (round.roundName === 2) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 1
			reorganizedRound.matches[0].match[1].size = 1
		}
		// Второй раунд
		else if (round.roundName === 1) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 2
			reorganizedRound.matches[0].match[1].size = 2
		}
		// Итог
		else if (round.roundName === 'Result') {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0] = {
				...reorganizedRound.matches[0].match[0],
				size: 2
			}
			reorganizedRound.matches[1].match[0] = {
				...reorganizedRound.matches[1].match[0],
				size: 2
			}
		}

		reorganizedRounds.push(reorganizedRound)
	}

	return reorganizedRounds
}

// Форматируем 8 игроков
function formattingFor8Players(rounds) {
	const reorganizedRounds = []

	for (const round of rounds) {
		const reorganizedRound = {
			roundName: round.roundName,
			matches: []
		}

		// Первый раунд
		if (round.roundName === 4) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 1
			reorganizedRound.matches[0].match[1].size = 1
			reorganizedRound.matches[1].match[0].size = 1
			reorganizedRound.matches[1].match[1].size = 1
		}
		// Второй раунд
		else if (round.roundName === 2) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 2
			reorganizedRound.matches[0].match[1].size = 2
			reorganizedRound.matches[1].match[0].size = 1
			reorganizedRound.matches[1].match[1].size = 1

			reorganizedRound.matches[1].size = 4
		}
		// Финал
		else if (round.roundName === 1) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 4
			reorganizedRound.matches[0].match[1].size = 1
			reorganizedRound.matches[1].match[0].size = 2
			reorganizedRound.matches[1].match[1].size = 1
		}
		// Итог
		else if (round.roundName === 'Result') {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0] = {
				...reorganizedRound.matches[0].match[0],
				size: 4
			}
			reorganizedRound.matches[1].match[0] = {
				...reorganizedRound.matches[1].match[0],
				size: 1
			}
			reorganizedRound.matches[2].match[0] = {
				...reorganizedRound.matches[2].match[0],
				size: 2
			}
			reorganizedRound.matches[3].match[0] = {
				...reorganizedRound.matches[3].match[0],
				size: 1
			}
		}

		reorganizedRounds.push(reorganizedRound)
	}

	return reorganizedRounds
}

// Форматируем 16 игроков
function formattingFor16Players(rounds) {
	const reorganizedRounds = []

	for (const round of rounds) {
		const reorganizedRound = {
			roundName: round.roundName,
			matches: []
		}

		// Первый раунд
		if (round.roundName === 8) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 1
			reorganizedRound.matches[0].match[1].size = 1
			reorganizedRound.matches[1].match[0].size = 1
			reorganizedRound.matches[1].match[1].size = 1
			reorganizedRound.matches[2].match[0].size = 1
			reorganizedRound.matches[2].match[1].size = 1
			reorganizedRound.matches[3].match[0].size = 1
			reorganizedRound.matches[3].match[1].size = 1
		}
		// Второй раунд
		else if (round.roundName === 4) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 2
			reorganizedRound.matches[0].match[1].size = 2
			reorganizedRound.matches[1].match[0].size = 2
			reorganizedRound.matches[1].match[1].size = 2
			reorganizedRound.matches[2].match[0].size = 1
			reorganizedRound.matches[2].match[1].size = 1
			reorganizedRound.matches[3].match[0].size = 1
			reorganizedRound.matches[3].match[1].size = 1

			reorganizedRound.matches[2].margin = 5
		}
		// Третий раунд
		else if (round.roundName === 2) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 4
			reorganizedRound.matches[0].match[1].size = 4
			reorganizedRound.matches[1].match[0].size = 1
			reorganizedRound.matches[1].match[1].size = 1
			reorganizedRound.matches[2].match[0].size = 2
			reorganizedRound.matches[2].match[1].size = 2
			reorganizedRound.matches[3].match[0].size = 1
			reorganizedRound.matches[3].match[1].size = 1

			reorganizedRound.matches[1].size = 4
			reorganizedRound.matches[3].size = 4
		}
		// Четвёртый раунд
		else if (round.roundName === 1) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 8
			reorganizedRound.matches[0].match[1].size = 1
			reorganizedRound.matches[1].match[0].size = 2
			reorganizedRound.matches[1].match[1].size = 1
			reorganizedRound.matches[2].match[0].size = 4
			reorganizedRound.matches[2].match[1].size = 1
			reorganizedRound.matches[3].match[0].size = 2
			reorganizedRound.matches[3].match[1].size = 1
		}
		// Итог
		else if (round.roundName === 'Result') {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0] = {
				...reorganizedRound.matches[0].match[0],
				size: 8
			}
			reorganizedRound.matches[1].match[0] = {
				...reorganizedRound.matches[1].match[0],
				size: 1
			}
			reorganizedRound.matches[2].match[0] = {
				...reorganizedRound.matches[2].match[0],
				size: 2
			}
			reorganizedRound.matches[3].match[0] = {
				...reorganizedRound.matches[3].match[0],
				size: 1
			}
			reorganizedRound.matches[4].match[0] = {
				...reorganizedRound.matches[4].match[0],
				size: 4
			}
			reorganizedRound.matches[5].match[0] = {
				...reorganizedRound.matches[5].match[0],
				size: 1
			}
			reorganizedRound.matches[6].match[0] = {
				...reorganizedRound.matches[6].match[0],
				size: 2
			}
			reorganizedRound.matches[7].match[0] = {
				...reorganizedRound.matches[7].match[0],
				size: 1
			}
		}

		reorganizedRounds.push(reorganizedRound)
	}

	return reorganizedRounds
}

function formattingFor32Players(rounds) {
	const reorganizedRounds = []

	for (const round of rounds) {
		const reorganizedRound = {
			roundName: round.roundName,
			matches: []
		}

		// Первый раунд
		if (round.roundName === 16) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 1
			reorganizedRound.matches[0].match[1].size = 1
			reorganizedRound.matches[1].match[0].size = 1
			reorganizedRound.matches[1].match[1].size = 1
			reorganizedRound.matches[2].match[0].size = 1
			reorganizedRound.matches[2].match[1].size = 1
			reorganizedRound.matches[3].match[0].size = 1
			reorganizedRound.matches[3].match[1].size = 1
			reorganizedRound.matches[4].match[0].size = 1
			reorganizedRound.matches[4].match[1].size = 1
			reorganizedRound.matches[5].match[0].size = 1
			reorganizedRound.matches[5].match[1].size = 1
			reorganizedRound.matches[6].match[0].size = 1
			reorganizedRound.matches[6].match[1].size = 1
			reorganizedRound.matches[7].match[0].size = 1
			reorganizedRound.matches[7].match[1].size = 1
		}
		// Второй раунд
		if (round.roundName === 8) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 2
			reorganizedRound.matches[0].match[1].size = 2
			reorganizedRound.matches[1].match[0].size = 2
			reorganizedRound.matches[1].match[1].size = 2
			reorganizedRound.matches[2].match[0].size = 2
			reorganizedRound.matches[2].match[1].size = 2
			reorganizedRound.matches[3].match[0].size = 2
			reorganizedRound.matches[3].match[1].size = 2
			reorganizedRound.matches[4].match[0].size = 1
			reorganizedRound.matches[4].match[1].size = 1
			reorganizedRound.matches[5].match[0].size = 1
			reorganizedRound.matches[5].match[1].size = 1
			reorganizedRound.matches[6].match[0].size = 1
			reorganizedRound.matches[6].match[1].size = 1
			reorganizedRound.matches[7].match[0].size = 1
			reorganizedRound.matches[7].match[1].size = 1

			reorganizedRound.matches[4].margin = 7
		}
		// Третий раунд
		else if (round.roundName === 4) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 4
			reorganizedRound.matches[0].match[1].size = 4
			reorganizedRound.matches[1].match[0].size = 4
			reorganizedRound.matches[1].match[1].size = 4
			reorganizedRound.matches[2].match[0].size = 1
			reorganizedRound.matches[2].match[1].size = 1
			reorganizedRound.matches[3].match[0].size = 1
			reorganizedRound.matches[3].match[1].size = 1
			reorganizedRound.matches[4].match[0].size = 2
			reorganizedRound.matches[4].match[1].size = 2
			reorganizedRound.matches[5].match[0].size = 2
			reorganizedRound.matches[5].match[1].size = 2
			reorganizedRound.matches[6].match[0].size = 1
			reorganizedRound.matches[6].match[1].size = 1
			reorganizedRound.matches[7].match[0].size = 1
			reorganizedRound.matches[7].match[1].size = 1

			reorganizedRound.matches[2].margin = 5
			reorganizedRound.matches[4].margin = 6
			reorganizedRound.matches[5].margin = -1
			reorganizedRound.matches[6].margin = 4.2
		}
		// Четвёртый раунд
		else if (round.roundName === 2) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 8
			reorganizedRound.matches[0].match[1].size = 8
			reorganizedRound.matches[1].match[0].size = 1
			reorganizedRound.matches[1].match[1].size = 1
			reorganizedRound.matches[2].match[0].size = 2
			reorganizedRound.matches[2].match[1].size = 2
			reorganizedRound.matches[3].match[0].size = 1
			reorganizedRound.matches[3].match[1].size = 1
			reorganizedRound.matches[4].match[0].size = 2
			reorganizedRound.matches[4].match[1].size = 2
			reorganizedRound.matches[5].match[0].size = 1
			reorganizedRound.matches[5].match[1].size = 1
			reorganizedRound.matches[6].match[0].size = 2
			reorganizedRound.matches[6].match[1].size = 2
			reorganizedRound.matches[7].match[0].size = 1
			reorganizedRound.matches[7].match[1].size = 1

			reorganizedRound.matches[1].margin = 4
			reorganizedRound.matches[2].margin = 3
			reorganizedRound.matches[3].margin = 4.1
			reorganizedRound.matches[4].margin = 4.1
			reorganizedRound.matches[5].margin = 3
			reorganizedRound.matches[6].margin = 3
			reorganizedRound.matches[7].margin = 3
		}
		// Финал
		else if (round.roundName === 1) {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0].size = 9
			reorganizedRound.matches[0].match[1].size = 1
			reorganizedRound.matches[1].match[0].size = 2
			reorganizedRound.matches[1].match[1].size = 1
			reorganizedRound.matches[2].match[0].size = 4
			reorganizedRound.matches[2].match[1].size = 1
			reorganizedRound.matches[3].match[0].size = 4
			reorganizedRound.matches[3].match[1].size = 1
			reorganizedRound.matches[4].match[0].size = 4
			reorganizedRound.matches[4].match[1].size = 1
			reorganizedRound.matches[5].match[0].size = 2
			reorganizedRound.matches[5].match[1].size = 1
			reorganizedRound.matches[6].match[0].size = 4
			reorganizedRound.matches[6].match[1].size = 1
			reorganizedRound.matches[7].match[0].size = 2
			reorganizedRound.matches[7].match[1].size = 1
		}
		// Итог
		else if (round.roundName === 'Result') {
			for (let i = 0; i < round.matches.length; i += 2) {
				reorganizedRound.matches.push({
					match: round.matches.slice(i, i + 2)
				})
			}

			reorganizedRound.matches[0].match[0] = {
				...reorganizedRound.matches[0].match[0],
				size: 9
			}
			reorganizedRound.matches[1].match[0] = {
				...reorganizedRound.matches[1].match[0],
				size: 1
			}
			reorganizedRound.matches[2].match[0] = {
				...reorganizedRound.matches[2].match[0],
				size: 2
			}
			reorganizedRound.matches[3].match[0] = {
				...reorganizedRound.matches[3].match[0],
				size: 1
			}
			reorganizedRound.matches[4].match[0] = {
				...reorganizedRound.matches[4].match[0],
				size: 4
			}
			reorganizedRound.matches[5].match[0] = {
				...reorganizedRound.matches[5].match[0],
				size: 1
			}
			reorganizedRound.matches[6].match[0] = {
				...reorganizedRound.matches[6].match[0],
				size: 4
			}
			reorganizedRound.matches[7].match[0] = {
				...reorganizedRound.matches[7].match[0],
				size: 1
			}
			reorganizedRound.matches[8].match[0] = {
				...reorganizedRound.matches[8].match[0],
				size: 4
			}
			reorganizedRound.matches[9].match[0] = {
				...reorganizedRound.matches[9].match[0],
				size: 1
			}
			reorganizedRound.matches[10].match[0] = {
				...reorganizedRound.matches[10].match[0],
				size: 2
			}
			reorganizedRound.matches[11].match[0] = {
				...reorganizedRound.matches[11].match[0],
				size: 1
			}
			reorganizedRound.matches[12].match[0] = {
				...reorganizedRound.matches[12].match[0],
				size: 4
			}
			reorganizedRound.matches[13].match[0] = {
				...reorganizedRound.matches[13].match[0],
				size: 1
			}
			reorganizedRound.matches[14].match[0] = {
				...reorganizedRound.matches[14].match[0],
				size: 2
			}
			reorganizedRound.matches[15].match[0] = {
				...reorganizedRound.matches[15].match[0],
				size: 1
			}
		}

		reorganizedRounds.push(reorganizedRound)
	}

	return reorganizedRounds
}

// Определяем количество игроков и вызываем нужную функцию
export function converterApiToOlympicFormat(rounds) {
	const firstRoundMatches = rounds[0].matches.flat().length * 2

	if (firstRoundMatches === 2) {
		return formattingFor2Players(rounds)
	}
	else if (firstRoundMatches === 4) {
		return formattingFor4Players(rounds)
	}
	else if (firstRoundMatches === 8) {
		return formattingFor8Players(rounds)
	}
	else if (firstRoundMatches === 16) {
		return formattingFor16Players(rounds)
	}
	else if (firstRoundMatches === 32) {
		return formattingFor32Players(rounds)
	}
}