import { memo, useState } from 'react'
import SmoothCollapse from 'react-smooth-collapse'

import './StageBuildGroup.scss'
import { ReactComponent as ArrowDownIcon } from '../../../icons/arrow_down.svg'
import GroupNodes from '../GroupNodes/GroupNodes'
import { generateGroupOrLevelsName } from '../../../utils/functions'
import StageGroupIcon from '../../../reusableComponents/StageGroupIcon/StageGroupIcon'

function StageBuildGroup({ levelIndex, groupIndex, group, nodesQuantity }) {
	const [isNewGroup, setIsNewGroup] = useState(group.new ? true : false)
	const [showGroup, setShowGroup] = useState({
		id: group.uid,
		opened: isNewGroup || false
	})

	// Функция возвращающая количество игроков в Группе в двух вариантах (4 или 3/4)
	function nodesLength() {
		const activeNodes = group.nodes.filter(node => !node.disabled)
		return activeNodes.length
	}

	function handleGroupArrow() {
		setIsNewGroup(false)
		setShowGroup(prev => {
			return {
				...prev,
				opened: !prev.opened
			}
		})
	}

	return (
		<>
			<div className="stage-build-group">
				<div
					className={'stage-build-group__header'}
				>
					<div className="stage-build-group__name-wrapper">
						<p className="stage-build-group__header-name-group">
							{generateGroupOrLevelsName(group.order_number - 1)}
						</p>
					</div>

					<div className="group__header-wrapper">
						<StageGroupIcon type={group?.type} />

						<p className="stage-build-group__header-counter">
							{nodesLength()}
						</p>

						<div
							className="stage-build-group__icon-container"
							onClick={handleGroupArrow}
						>
							<ArrowDownIcon
								className={`arrow-down__icon ${showGroup.opened && showGroup.id === group.uid ? 'arrow--up' : 'arrow--down'}`}
							/>
						</div>
					</div>
				</div>

				<SmoothCollapse expanded={showGroup.opened && showGroup.id === group.uid}>
					<GroupNodes
						levelIndex={levelIndex}
						groupIndex={groupIndex}
						group={group}
						nodesQuantity={nodesQuantity}
					/>
				</SmoothCollapse>
			</div>
		</>
	)
}

export default memo(StageBuildGroup)