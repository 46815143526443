import { createSlice } from '@reduxjs/toolkit'

import { getLocalStorageItem, parseObject, setItemInLocalStorage } from '../../utils/functions'
import { COMMUNITY_COLORS } from '../../utils/constants'

import communityOptions from './communityOptions.json'

function getColor(uid, communities) {
	if (!uid || !communities || communities.length < 1 || !Array.isArray(communities)) {
		return COMMUNITY_COLORS[0]
	}

	try {
		const communityIndex = JSON.parse(communities).findIndex(item => item.uid === uid)
		return COMMUNITY_COLORS[communityIndex >= 0 ? communityIndex : 0]
	} catch(e) {
		return COMMUNITY_COLORS[0]
	}
}

const communitySlice = createSlice({
	name: 'community',
	initialState: {
		community: {},
		communityName: getLocalStorageItem('communityName'),
		communityUid: getLocalStorageItem('communityUid'),
		options: parseObject('communityOptions', getLocalStorageItem) || {},
		communityColor: getColor(getLocalStorageItem('communityUid'), getLocalStorageItem('communities'))
	},
	reducers: {
		setCommunity(state, action) {
			const options = communityOptions[action.payload.tier]
			setItemInLocalStorage('communityOptions', JSON.stringify(options))

			state.options = options
			state.community = action.payload
		},
		setCommunityUid(state, action) {
			setItemInLocalStorage('communityUid', action.payload)
			state.communityUid = action.payload

			const communities = getLocalStorageItem('communities')
			const newColor = getColor(action.payload, communities)
			state.communityColor = newColor
		},
		setCommunityName(state, action) {
			setItemInLocalStorage('communityName', action.payload)
			state.communityName = action.payload
		}
	}
})

export const {
	setCommunity,
	setCommunityName,
	setCommunityUid
} = communitySlice.actions
export default communitySlice.reducer